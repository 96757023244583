import { ComputerIcon, HelpIcon, OfficeBuildingIcon, RocketshipIcon } from '@cvent/carina/components/Icon';
import { Tile } from '@cvent/carina/components/Tile';
import NextLink from 'next/link';

type CustomCardProps = {
  href: string;
  title: string;
};

export default function CustomCard({ href, title }: CustomCardProps) {
  const iconStyle = {
    paddingRight: '10px',
    paddingTop: '8px'
  };
  function iconPicker() {
    if (title === 'Events') {
      return <RocketshipIcon size="l" />;
    }
    if (title === 'About') {
      return <HelpIcon size="l" />;
    }
    if (title === 'Devices') {
      return <ComputerIcon size="l" />;
    }
    return <OfficeBuildingIcon size="l" />;
  }
  return (
    <Tile selectable selectedIndicator={false}>
      <NextLink href={href} passHref>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={iconStyle}>{iconPicker()}</div>
          <h1>{title}</h1>
        </div>
      </NextLink>
    </Tile>
  );
}
