import CustomCard from '@components/CustomCard';
import { Col } from '@cvent/carina/components/Col';
import { useNavigation } from '@cvent/carina/components/Navigation';
import { Row } from '@cvent/carina/components/Row';
import { useTheme } from '@cvent/carina/components/ThemeProvider/useTheme';
import { useTranslate } from 'nucleus-text';
import PropTypes from 'prop-types';

function Content({ color, text, minHeight, children }) {
  return (
    <div
      style={{
        backgroundColor: color,
        boxSizing: 'border-box',
        color: '#1A2026',
        fontFamily: "'Rubik', 'Helvetica', 'Arial'",
        fontSize: '1.875rem',
        minHeight: minHeight || 70,
        padding: '1rem',
        width: '100%'
      }}
    >
      {text}
      {children}
    </div>
  );
}

Content.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  minHeight: PropTypes.number,
  children: PropTypes.node
};

function Example() {
  const padding = { start: 8, end: 8, top: 8, bottom: 8 };
  const { translate } = useTranslate();

  // Component for adding background color to the example.
  const { globalNav } = useNavigation();
  const { backgroundColor } = useTheme();
  return (
    <div>
      <Row margin={-8}>
        <Col padding={padding} m={{ width: 'fill' }}>
          <Content text="" color={backgroundColor.neutral.base} minHeight={300}>
            <Row margin={-8}>
              {globalNav &&
                globalNav.map(
                  item =>
                    item.items.length === 0 && (
                      <Col key={item.title} padding={padding} l={{ width: 1 / 2 }}>
                        <CustomCard
                          href={item.url.href}
                          title={translate((item as unknown as { titleKey: string }).titleKey)}
                        />
                      </Col>
                    )
                )}
            </Row>
          </Content>
        </Col>
      </Row>
    </div>
  );
}

export default Example;
